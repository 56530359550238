import React from 'react';

import SEO from '../components/seo';

import Layout from '../components/Layout/Layout';
import Header from '../components/Header/Header';
import NotFound from '../components/NotFound/NotFound';
import Footer from '../components/Footer/Footer';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404 Page Not Found" />
    <Header hideLinks />
    <NotFound />
    <Footer hideLinks />
  </Layout>
);

export default NotFoundPage;
