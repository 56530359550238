import styled from 'styled-components';
import * as defaultTheme from '../variables';

import HeroBgMobile from '../../images/hero-bg-mobile.jpg';
import HeroBgDesktop from '../../images/hero-bg-desktop.jpg';

const NotFoundStyled = styled.section`
  position: relative;
  background-repeat: no-repeat;

  @media (${p => p.theme.xsMax}) {
    margin-top: ${p => p.theme.space * 5}px;
    background-image: url(${HeroBgMobile});
    background-size: 100% auto;
  }

  @media (${p => p.theme.smMin}) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 475px;
    margin-top: ${p => p.theme.space * 8}px;
    background-image: url(${HeroBgDesktop});
    background-position: -120px 60%;
    background-size: 150%;
  }

  @media (${p => p.theme.lgMin}) {
    height: 545px;
    padding-top: 240px;
    background-position: -70px 60%;
    background-size: 120%;
  }

  @media (${p => p.theme.xlMin}) {
    background-position: 0 55%;
    background-size: cover;
  }

  .NotFound__inner {
    width: 100%;
    max-width: ${p => p.theme.maxWidth}px;
    margin: 0 auto;
    padding: 0 ${p => p.theme.space * 3}px;

    @media (${p => p.theme.xsMax}) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @media (${p => p.theme.smMin}) {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 50px;
    }

    @media (${p => p.theme.lgMin}) {
      padding-bottom: 100px;
    }
  }

  .NotFound__title {
    margin: 0;
    color: ${p => p.theme.red};
    font-family: 'MrBlack';
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-shadow: 0 0 2px ${p => p.theme.textColorBlack};
    -webkit-font-smoothing: initial;
    -webkit-transform-style: preserve-3d;

    @media (max-width: 459px) {
      padding-top: 34%;
    }

    @media (min-width: 460px) {
      padding-top: 38%;
    }

    @media (${p => p.theme.xsMax}) {
      font-size: 56px;
    }

    @media (${p => p.theme.smMin}) {
      padding-top: 0;
      font-size: 66px;
    }

    @media (${p => p.theme.lgMin}) {
      font-size: 74px;
      letter-spacing: 1.14px;
    }

    p {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: ${p => p.theme.space * 6}px;
      color: ${p => p.theme.textColorWhite};

      @media (${p => p.theme.xsMax}) {
        font-size: 34px;
      }

      @media (${p => p.theme.smMin}) {
        margin-left: 0;
        font-size: 44px;
      }

      @media (${p => p.theme.lgMin}) {
        font-size: 50px;
      }
    }
  }

  .NotFound__subtitle {
    max-width: 350px;
    color: ${p => p.theme.textColorWhite};
    font-size: 15px;
    line-height: 1.3;
    text-shadow: 0 0 5px ${p => p.theme.textColorBlack},
      1px 1px 2px ${p => p.theme.textColorBlack},
      2px 2px 2px ${p => p.theme.textColorBlack},
      3px 3px 2px ${p => p.theme.textColorBlack},
      -1px -1px 2px ${p => p.theme.textColorBlack},
      -2px -2px 2px ${p => p.theme.textColorBlack},
      -3px -3px 2px ${p => p.theme.textColorBlack};

    @media (max-width: 459px) {
      padding-top: 12%;
    }

    @media (min-width: 460px) {
      padding-top: 15%;
    }

    @media (${p => p.theme.xsMax}) {
      text-align: center;
    }

    @media (${p => p.theme.smMin}) {
      margin-top: 8px;
      padding-top: 0;
    }
  }

  .NotFound__cans {
    @media (${p => p.theme.xsMax}) {
      max-width: 80%;
      margin: ${p => p.theme.space * 4}px 0;
    }

    @media (${p => p.theme.smMin}) {
      position: absolute;
      right: 24px;
      bottom: -50px;
      height: 280px;

      &.--de {
        bottom: -20px;
        height: 220px;
      }
    }

    @media (${p => p.theme.mdMin}) {
      right: 5%;
      height: 300px;

      &.--de {
        height: 280px;
      }
    }

    @media (${p => p.theme.lgMin}) {
      right: 18%;
      bottom: -45px;
      height: 340px;

      &.--de {
        height: 315px;
      }
    }
  }

  .NotFound__btn {
    max-width: 250px;
    height: 40px;
    padding: 0 16px;
    color: ${p => p.theme.textColorBlack};
    background-color: ${p => p.theme.secondary};
    font-size: 18px;
    font-weight: 900;
    letter-spacing: 0.91px;
    text-align: center;
    line-height: 40px;
    text-transform: uppercase;
  }
`;

NotFoundStyled.defaultProps = {
  theme: defaultTheme,
};

export default NotFoundStyled;
