import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage, defineMessages } from 'react-intl';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';
import { Link } from 'gatsby-plugin-intl';

import NotFoundStyled from './NotFound.style';
import config from './config';

const messages = defineMessages({
  title1: {
    id: 'notFound.title1',
    defaultMessage: '404',
  },
  title2: {
    id: 'notFound.title2',
    defaultMessage: 'Page Not Found',
  },
  subtitleError: {
    id: 'notFound.subtitleError',
    defaultMessage: 'Click the button below to visit the homepage.',
  },
  homeBtn: {
    id: 'notFound.homeBtn',
    defaultMessage: 'Visit Homepage',
  },
});

const NotFound = ({ intl }) => {
  const localeConfig = config[intl.locale] || config[Object.keys(config)[0]];

  return (
    <NotFoundStyled>
      <div className="NotFound__inner">
        <h1 className="NotFound__title">
          <FormattedMessage {...messages.title1} />
          <p>
            <FormattedMessage {...messages.title2} />
          </p>
        </h1>

        <p className="NotFound__subtitle">
          <FormattedMessage {...messages.subtitleError} />
        </p>

        <Link className="NotFound__btn" to="/">
          <FormattedMessage {...messages.homeBtn} />
        </Link>

        <img
          className={cx('NotFound__cans', { '--de': intl.locale === 'de-DE' })}
          src={localeConfig.cans.src}
          alt={localeConfig.cans.alt}
        />
      </div>
    </NotFoundStyled>
  );
};

NotFound.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(NotFound);
